@import './assets/fonts/gotham-fonts.css';

html, body {
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.467;
  color: #333;
  height: 100%;
  min-width: 480px;
  min-height: 480px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: 639px) {
    min-width: 320px;
    min-height: 320px;
  }
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

*[hidden] {
  display: none !important;
}