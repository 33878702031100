.root {
  flex: 1 1;
  height: 0;
  display: flex;
  flex-direction: column;
  min-width: 640px;
}

.nav {
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: stretch;
  flex: 0 0 45px;
  box-shadow: 0 0 5px 1px #ddd;
  position: relative;
  z-index: 50;

  .item {
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: solid 1px #ddd;
    cursor: pointer;

    &:first-child {
      border-left: solid 1px transparent;
    }

    &.active {
      box-shadow: inset 0 -3px 0 0 #E40421;
    }
  }
}

.body {
  flex: 1;
  position: relative;
  height: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 40;
}

.out {
  margin: 0;
  border-left: solid 1px #ddd;
  width: 45px;
  height: 45px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #f9f9f9;
  }
}
