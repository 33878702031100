.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
}

.item {
  background-color: #F2F2F2;
  padding: 0 15px;
  flex: 0 0 60px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 10px;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }
}

.icon {
  color: #1fa83a;
  margin-right: 12px;
}

.header {
  box-shadow: 0 5px 5px 1px #f2f2f2;
}

.body {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

INPUT.search {
  padding: 30px;
  border: 0;
}

div.searchWrap {
  border: 0;
}