@mixin placeholder {
  ::-webkit-input-placeholder {
    @content
  }
  :-moz-placeholder {
    @content
  }
  ::-moz-placeholder {
    @content
  }
  :-ms-input-placeholder {
    @content
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.root {
  outline: 0;
  color: #333;
  font-weight: bold;
  border-radius: 0;
  font-size: 16px;
  line-height: 20px;
  padding: 10px;
  background: #fff;
  width: 100%;
  border: solid 1px #767676;
  height: 40px;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", Arial, sans-serif;
  
  &:disabled{
    background: #ededed;
    opacity: .5;
  }
}

@include placeholder {
  font-size: 16px;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", Arial, sans-serif;
  color: #767676;
  font-weight: bold;
}

.wrapper {
  position: relative;
  background-color: #fff;
  border: 1px solid #767676;

  .root {
    border: 0;
    width: 100%;
  }
}

.label {
  font-size: 13px;
  line-height: 1.375;
  padding: 10px 10px 0;
  color: #767676;
  z-index: 0;
  margin-bottom: -4px;
}

.icon {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: solid 1px transparent;
  color: #333;
  font-size: 20px;
  cursor: pointer;
}

.switch {
  display: block;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  position: relative;

  span {
    display: block;
    width: 42px;
    height: 24px;
    border-radius: 12px;
    border: solid 1px #ddd;
    cursor: pointer;
    background: #fff;
    transition: all linear .15s;

    &:before {
      position: absolute;
      display: block;
      width: 18px;
      height: 18px;
      left: 3px;
      top: 3px;
      background: #999;
      content: '';
      border-radius: 50%;
      transition: all linear .15s;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    margin: 0;

    &:checked ~ span {
      border: solid 1px #0e4194;
      background: #0e4194;

      &:before {
        background: #fff;
        left: calc(100% - 21px);
      }
    }

    &:disabled ~ span {
      opacity: .5;
      cursor: not-allowed;
    }
  }

}