.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
}

.header {
  padding: 20px;
}

.body {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  padding: 0 20px;
}

.item {
  border: solid 1px #ddd;
  border-radius: 3px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 10px;
  cursor: pointer;
  min-height: 60px;

  &.active {
    border-color: rgba(31, 168, 58, 0.4);
  }
}

.icon {
  color: #1fa83a;
  margin-right: 12px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  position: relative;
}

.emptyText {
  margin: auto;
  font-size: 24px;
  line-height: 30px;
  padding: 0 0 40px;
  color: #E40421;
}

.emptyReset {
  position: relative;
}

.mrAuto {
  margin-right: auto;
}

.statusNode {
  text-align: center;
  padding: 25px 0;

}

.reset {
  position: absolute;
  left: 0;
  bottom: 100%;
  font-size: 18px;
  opacity: .4;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.modal {
  text-align: center;
  padding: 16px 0 0;
  font-size: 18px;
  line-height: 22px;
}

.payment {
  position: fixed;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  align-items: center;
  justify-content: center;
}

.qrNode {
  position: relative;
  margin: auto;
}

.qr {
  position: relative;
  z-index: 5;
  height: auto;
  max-width: 100%;
  width: 100%;
}

.payedIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 250px;
  width: 250px;
  text-align: center;
  margin: -125px 0 0 -125px;
  color: #3ccd3c;
  z-index: 10;
}

.failedIcon {
  @extend .payedIcon;
  color: #E40421;
}

.basket {
  margin: 0 0 6px;
}

.field {
  margin-top: 10px;
}

.basketItem {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 6px 0;
}

.basketName {
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.basketTotal {
  padding: 6px 0;
  font-size: 18px;
  font-weight: 700;
  text-align: right;
}

.basketPrice {
  flex: 0;
  padding: 0 0 0 20px;
}

.payerDialogBody {
  overflow: unset !important;
}

.phoneContainer {
  margin-top: 10px;
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", Arial, sans-serif !important;
}

.phoneInput {
  color: #333 !important;
  font-weight: bold;
  border-radius: 0 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  background: #fff !important;
  width: 100% !important;
  border: solid 1px #767676 !important;
  height: 40px !important;
}

.phoneCountryBtn {
  border: solid 1px #767676 !important;
  border-radius: 0 !important;
}

.phoneSearch input {
  padding-bottom: 3px !important;

  &::placeholder {
    font-size: inherit !important;
    font-family: inherit !important;
    color: #757575 !important;
    font-weight: inherit !important;
  }
}

.dotPulse {
  position: relative;
  display: block;
  margin: 15px auto 0;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #E40421;
  color: #E40421;
  box-shadow: 9999px 0 0 -5px #E40421;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
  opacity: .85;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #E40421;
    color: #E40421;
  }

  &:before {
    box-shadow: 9984px 0 0 -5px #E40421;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
    right: 100%;
  }

  &:after {
    box-shadow: 10014px 0 0 -5px #E40421;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
    left: 100%;
  }

}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #E40421;
  }
  30% {
    box-shadow: 9984px 0 0 2px #E40421;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #E40421;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #E40421;
  }
  30% {
    box-shadow: 9999px 0 0 2px #E40421;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #E40421;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #E40421;
  }
  30% {
    box-shadow: 10014px 0 0 2px #E40421;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #E40421;
  }
}

@media screen and (max-width: 639px) {
  .modal {
    font-size: 16px;
  }
}