.wrapper {
  display: block;

  input {
    display: block;
    width: 100%;
    outline: 0;
    color: #333;
    font-weight: bold;
    border-radius: 0;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    background: #fff;
    border: solid 1px #767676;
    height: 40px;
    font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", Arial, sans-serif;
  }
}

:global {
  .react-datepicker {
    border-radius: 0;
    font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", Arial, sans-serif;

    &__day,
    &__month-text,
    &__quarter-text,
    &__year-text {
      &:hover {
        border-radius: 0;
      }

      &--keyboard {
        &-selected {
          border-radius: 0;
          background-color: #0e4194;
          color: #fff;
          
          &:hover {
            background-color: #E40421;
          }
        }
      }
    }

  }

}
