.root {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
}

.dialog {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 3px;
  max-width: 80%;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
  min-width: 500px;
  max-height: 85%;
}

.title {
  font-size: 20px;
  line-height: 30px;
  padding: 15px 20px;
  background: #767676;
  color: #fff;
}

.body {
  padding: 20px;
  flex: 1;
  height: 0;
  overflow: auto;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

}

@media screen and (max-width: 639px) {
  .dialog {
    min-width: 0;
    flex: 1;
  }
  .title {
    font-size: 18px;
  }
}