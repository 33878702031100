.root {
  padding: 0 20px;
  height: 70px;
  background: #E40421;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
}

.name {
  font-size: 24px;
  line-height: 26px;
}

.dropdown {
  font-size: 24px;
  line-height: 30px;
  padding: 5px 10px;
  margin-left: -10px;
  display: flex;
  align-items: center;
}

.icon {
  height: 24px;
  margin-left: 12px;
}

.basket {
  margin: auto 0 auto auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  position: relative;
  white-space: nowrap;
  flex-shrink: 0;
  padding: 0 0 0 25px;
}

.count {
  position: absolute;
  right: -6px;
  top: -6px;
  background: #0E4194;
  color: #fff;
  padding: 0 4px 0 3px;
  border-radius: 15px;
  height: 26px;
  font-size: 12px;
  line-height: 26px;
  min-width: 26px;
  text-align: center;
  font-weight: 700;
}

.total {
  font-weight: 700;
  padding: 0 15px 0 0;
}