.root {
  margin: auto;
}

.group {
  position: relative;
  box-shadow: 0 0 5px 1px #ddd;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 35px;

  .headline {
    display: flex;
    align-items: center;

    .add {
      margin-left: 12px;
      min-width: 0;
      padding: 0 18px;
      line-height: 40px;
    }
  }

  .name {
    font-size: 20px;
    line-height: 24px;
    white-space: nowrap;
    margin-right: 15px;
  }

  .field {
    flex: 1;
    border-color: #ddd;
    border-width: 0 0 1px 0;
    font-weight: 400;
  }

  .list {
    margin: 16px -5px 0;
  }
}

.controls {
  text-align: right;
}

.mail {
  margin: 5px;
  background: #eee;
  border-radius: 10px;
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  padding: 0 12px;
  position: relative;
  display: inline-flex;
  align-items: center;

  .remove {
    color: #e40421;
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-left: 4px;
  }
}

.table {
  border-collapse: collapse;
  min-width: 100%;
  height: 1px;

  .emails {
    padding: 10px;

    div {
      padding: 8px 2px;
    }

    .emailsWrapper {
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }

  .horizontalDelimiter {
    border-top: solid 1px #4e4e4e;
  }

  .horizontalSubDelimiter {
    border-top: solid 1px #ddd;
  }

  .verticalDelimiter {
    border-right: solid 1px #ddd;

    &:last-child {
      border-right: 0;
    }
  }

  .firstColumn {
    border-right: 0;
    padding: 12px;
  }

  .upperHint {
    position: relative;
    font-weight: bold;
    font-size: 0.75em;
    border-left: 0;
    border-bottom: 0;
    padding: 0;
    max-height: 200px;
  }

  .lowerHint {
    position: relative;
    font-weight: bold;
    font-size: 0.75em;
    border-left: 0;
    border-top: 0;
    padding: 0;
    max-height: 200px;
  }
}
