.filter {
  padding: 14px 20px;
  flex: 0 0 70px;
  box-shadow: 0 0 5px 1px #ddd;

}

.list {
  flex: 1;
  overflow: auto;
  padding: 10px 0;
}

.item {
  padding: 12px 20px;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  &:hover {
    background: #f9f9f9;
  }

  &.active {
    color: #E40421;
    background: #f9f9f9;
  }
}