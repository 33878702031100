.badge {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: wrap-content;

  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.default {
  background-color: rgb(241, 243, 247);
}

.primary {
  color: #fff;
  background-color: rgba(13, 110, 253, 1);
}

.info {
  color: #fff;
  background-color: rgba(13, 202, 240, 1);
}
