.root {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #E40421;
  padding: 16px 20px;
  text-align: center;
  border-radius: 2px;
  min-width: 115px;
  transition: color 0.5s;
  text-decoration: inherit;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  border: 0;
  cursor: pointer;
  transform: translateZ(0);
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", Arial, sans-serif;

  &:before {
    content: "";
    background-color: #b2031a;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    transform: scaleX(0);
    opacity: 0;
    transition: all .075s linear;
  }

  &:hover,
  &:focus {
    &:before {
      transform: scaleX(1);
      opacity: 1;
    }
  }

  &[readonly],
  &[readonly="readonly"],
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  &.full {
    display: block;
    width: 100%;
    border-radius: 0;
    padding: 20px;
    line-height: 30px;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  &.small {
    padding: 8px 12px;
    min-width: 0;
  }
  
  
  @media screen and (max-width: 639px) {
    padding: 10px 15px;
    font-size: 14px;
    min-width: 80px;
  }
}