.root {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  box-shadow: 0 0 5px 1px #ddd;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
}

.name {
  flex: 1;
  word-break: break-word;
}

.price {
  display: flex;
  margin: -12px 0 -12px 15px;
  align-items: center;
  width: 120px;
  justify-content: flex-end;
  font-size: 20px;
  line-height: 20px;

  span {
    font-size: 16px;
    margin: 4px 0 0 6px;
  }
}

.actions {
  margin: -12px -12px -12px 6px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .btn {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 0 0 1px;

    &:hover {
      color: #0e4194;
      background: #f9f9f9;
    }

    &Red {
      @extend .btn;
      color: #E40421;

      &:hover {
        color: #E40421;
      }
    }

    &Trash {
      @extend .btn;

      &:hover {
        color: #E40421;
      }
    }

    &Green {
      @extend .btn;

      &:hover {
        color: #1fa83a;
      }

      color: #1fa83a;
    }

    &Text {
      @extend .btn;
      width: auto;
      padding: 0 4px;
      min-width: 50px;
      font-size: 14px;
      text-align: center;
      background: #f4f4f4;
      display: flex;
      align-items: center;
      margin: 0 0 0 4px;

      small {
        margin: 4px 0 0 1px;
      }

      &:hover {
        color: #fff;
        background: #0e4194;
      }

    }

  }
}

.info {
  margin-left: 10px;

  &.changed {
    color: #E40421;
  }

  .icon {
    margin: 0 4px 0 0;
    color: #E40421;
  }
}

.input {
  font-size: 20px;
  flex: 1 1;
  border-color: #ddd;
  border-width: 0 0 1px 0;
  font-weight: 400;
  text-align: right;
  padding: 3px 6px 1px;
  height: auto;

  &.invalid {
    border-color: #E40421;
    box-shadow: 0 0 3px 1px #E40421;
    border-radius: 4px;
  }
}

.list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px;

  .col {
    flex: 0 0 50%;
    padding: 0 10px;
  }

  @media screen and (max-width: 1023px) {
    display: block;
  }

  .item {
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    box-shadow: 0 0 5px 1px #ddd;
    border-radius: 4px;
    flex: 0 0 calc(50% - 20px);
    cursor: pointer;

    &.active,
    &:hover {
      background: #f4f4f4;
    }
  }
}

.row {
  display: flex;
  align-items: flex-start;
  margin: 0 -10px;

  .col {
    flex: 0 0 50%;
    padding: 0 10px;
  }
}

.mrAuto {
  margin-right: auto;
}

.order {
  align-items: stretch;
  padding: 0 0 0 16px;
  display: flex;
}

.label {
  margin: 0 0 2px 8px;
}

.field {
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }
}

.service {
  margin: -10px 0 20px;
}

.add {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.product {
  @extend .root;
  @extend .name;
  cursor: pointer;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &.checked {
    background: #f4f4f4;
  }
}

.overrule {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;

  th, td {
    text-align: left;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 20px;
    border-bottom: solid 1px #ddd;
    min-width: 100px;

    &:last-child {
      min-width: 0;
      padding-right: 24px;
    }
  }

  td {
    padding: 14px 12px;
    font-size: 14px;
    line-height: 18px;
  }

  tr:nth-child(even) td {
    background: #f4f4f4;
  }

  &Price {
    width: 100%;
  }
}