.root {
  flex: 1 1;
  height: 0;
  display: flex;
  position: relative;
}

.sidebar {
  flex: 0 0 350px;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 0 10px #ddd;
  position: relative;
  @media screen and (max-width: 1023px) {
    flex: 0 0 200px;
  }
}

.title {
  flex: 1;
}

.subTitle {
  font-size: 24px;
  line-height: 30px;
  margin: -10px 0 20px;
  display: flex;
  align-items: center;

  .actions {
    margin-left: auto;
    
  }
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 20;
}

.wrapper {
  flex: 1;
  height: 0;
  overflow: auto;
  padding: 35px;
  position: relative;
  z-index: 10;

  .content {
    max-width: 1200px;
    
    &.middle{
      margin: 0 auto;
    }
  }
}

.loader {
  position: absolute;
  background: rgba(255, 255, 255, .75);
}

.pageLoader {
  position: absolute;
  background: transparent;
}